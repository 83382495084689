import './EasySoftwareModules.scss'

import React, { FunctionComponent } from 'react'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import { SideCardNavigationLinkType } from 'Shared/components/PuxNavigation/PuxNavigation'
import PuxNavigation from 'Shared/components/PuxNavigation/PuxNavigation'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { t } from 'ttag'

const rootClass = `es-modules`

interface EasySoftwareModuleType {
  contentType: 'EasySoftwareModule'
  displayText: string
  moduleCore: PuxWysiwygHtmlBodyType
  moduleIcon: string
  moduleLinks: {
    contentItems: SideCardNavigationLinkType[]
  }
}

export interface EasySoftwareModulesType {
  displayText: string
  modulesItem: {
    contentItems: EasySoftwareModuleType[]
  }
}

const EasySoftwareModules: FunctionComponent<EasySoftwareModulesType> = (
  props
) => {
  const moduleData = props.modulesItem?.contentItems[0]
  if (!moduleData) {
    return null
  }

  return (
    <div className={rootClass}>
      <h2 className={`${rootClass}-title`}>{props.displayText}</h2>

      <div className={`${rootClass}-columns`}>
        <div className={`${rootClass}-core`}>
          <h3 className={`${rootClass}-heading`}>
            {t`Redmine.ModulesWidget.Core`}
          </h3>
          <div className={`${rootClass}-box`}>
            <h4>{moduleData.displayText}</h4>
            <PuxWysiwyg content={moduleData.moduleCore} inline />
          </div>
        </div>

        <span className={`${rootClass}-icon`}>
          <PuxIconBuilder icon={moduleData.moduleIcon} />
        </span>

        <div className={`${rootClass}-navigation`}>
          <h3 className={`${rootClass}-heading`}>
            {t`Redmine.ModulesWidget.Modules`}
          </h3>
          <PuxNavigation
            type={`widget`}
            items={moduleData.moduleLinks.contentItems}
          />
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareModules
